import React from "react";
import notFoundImg from "../../assets/img/404.png";

const NotFoundPage = () => {
  return (
    <>
      <div className="notFound">
        <div className="notFoundImg"></div>
      </div>
    </>
  );
};

export default NotFoundPage;
